import { useRef, useEffect, React, useState } from 'react'
import { CONFIG, getPara } from '../functions'
import Header from '../widgets/Header'
import MSGBox from '../widgets/MsgBox'
import axios, { Axios } from 'axios'

const AgentChat = () => {

    const messagesEndRef = useRef(null);
    const [message, setMessage] = useState("")
    const [lastEntry, setLasteEntry] = useState(0)
    const [chats, setChats] = useState([])
    const [uid, setUID] = useState('')
    const [dataCount, setDataCount] = useState(0)
    const [name, setName] = useState()

    const chatContainerRef = useRef(null);

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth"
            });
        }
    };

    useEffect(() => {
        scrollToBottom(); // Scroll to bottom whenever chats state updates
    }, [chats]);

    useEffect((e) => {

        const chat_id = getPara("agentchat", false);
        const uid = getPara("uuid", false);

        setUID(uid);
        setName("Support");

        setLasteEntry(0);

        syncMessages();
        scrollToBottom();
        scrollToBottom();
        scrollToBottom();

        const interval = setInterval(syncMessages, 3000); // Call every 3 seconds

        return () => {
            clearInterval(interval); // Cleanup function to clear interval when component unmounts
        };

    }, []);

    const syncMessages = async () => {

        const chat_id = getPara("agentchat", false);
        const uid = getPara("uuid", false);

        const formData = new FormData();
        formData.append("lid", document.getElementById("lid").innerText);
        formData.append("chat_id", chat_id);
        formData.append("uid", uid);
        formData.append("GET_ADMIN_MESSAGE", "true");

        var resp = await axios.post(CONFIG.APP_API, formData);
        // console.warn(resp.data)
        if (resp.data.status != 401) {

            setChats(chats => [...chats, ...resp.data.result])

            if (resp.data.result.length > 0) {
                // const last = resp.data.result[resp.data.result.length].id;
                var last = resp.data.result[resp.data.result.length - 1].id
                setLasteEntry(last)

                document.getElementById("lid").innerText = last;
            }
            scrollToBottom();
        }

    }

    if (getPara("agentchat", false) === undefined) {
        window.location.href = "/503"
    }
    return (
        <>
            <div id="lid">0</div>
            <div className="my-body" >
                <Header />

                <div className="msg-list" id='msg-list' ref={chatContainerRef} style={{ maxHeight: "75vh", overflowY: "scroll" }}>


                    {chats != undefined && chats.length > 0 && <>
                        {chats.map((itm, i) =>
                            <MSGBox date={itm.date + " " + itm.time}
                                name={itm.senderName}
                                key={i}
                                isClient={itm.sender == uid ? true : false}
                                isRead={itm.seen == "Yes" ? true : false}
                                message={itm.description}
                            ></MSGBox>)}
                    </>}

                    <div className="sizedBox"></div>
                    <div ref={messagesEndRef} />
                </div>


                <div className="footer-msg">
                    <div className="row">

                        <textarea
                            value={message}
                            onChange={(e) => { setMessage(e.target.value) }} name="message" id="msg" cols="30" rows="5" className='my-text-area'></textarea>
                        <button onClick={() => { sendMessage() }} >Send</button>
                        <button>😊</button>
                        <button>📃</button>
                        <button>📷</button>
                    </div>
                </div>
            </div>
        </>
    )

    async function sendMessage() {
        const formData = new FormData();
        formData.append("message", message);
        formData.append("uuid", getPara("uuid", false));
        formData.append("chat_id", getPara("agentchat", false));
        formData.append("SEND_MESSAGE", "true");

        setMessage('')
        var resp = await axios.post(CONFIG.APP_API, formData);
        console.warn(resp.data.status)
        syncMessages();
        scrollToBottom();
        scrollToBottom();
        scrollToBottom();
    }
}

export default AgentChat