
import './App.css';
import { BrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom';
import Auth from './pages/Auth';
import Chat from './pages/Chat';
import AgentChat from './pages/AgentChat';
function App() {
  return (
    <> 
      <Routes>
        <Route path='/' Component={Auth} ></Route> 
        <Route path='/api_key/:api/domain/:domain' Component={Auth} ></Route> 
        <Route path='/chat' Component={Chat} ></Route>
        <Route path='/chat/:id/uuid/:uuid' Component={Chat} ></Route>
        <Route path='/chat/:id/uuid/:uuid/name/:name' Component={Chat} ></Route>
        <Route path='/chat/:id/uuid/:uuid/name/:name/api_key/:api_key' Component={Chat} ></Route>

        <Route path='/agentChat/:id' Component={AgentChat} ></Route>
        <Route path='/agentChat/:id/uuid/:uid' Component={AgentChat} ></Route>
        <Route path='/agentChat/:id/uuid/:uid/name/:name' Component={AgentChat} ></Route>
      </Routes>

    </>
  );
}

export default App;
