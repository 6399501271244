import { useRef, useEffect, React, useState } from 'react'
import { CONFIG, getPara } from '../functions'
import Header from '../widgets/Header'
import MSGBox from '../widgets/MsgBox'
import axios, { Axios } from 'axios'

const Chat = () => {

    const messagesEndRef = useRef(null);
    const [message, setMessage] = useState("")
    const [lastEntry, setLastEntry] = useState(0)
    const [chats, setChats] = useState([])
    const [uid, setUID] = useState('')
    const chatContainerRef = useRef(null);
    const [lid, setLid] = useState(0)

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth"
            });
        }
    };

    useEffect(() => {
        scrollToBottom(); // Scroll to bottom whenever chats state updates

        setInterval(() => {
            // console.error("Interval Process");
            setLastEntry(lastEntry + 1)
        }, 5000);

    }, [chats]);

    useEffect(() => {
        setUID(getPara("uuid", false));

        syncMessages();
        scrollToBottom();
        scrollToBottom();
        scrollToBottom();

        const interval = setInterval(syncMessages, 3000); // Call every 3 seconds

        return () => {
            clearInterval(interval); // Cleanup function to clear interval when component unmounts
        };

    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [chats])

    async function syncMessages() {

        const chat_id = getPara("chat", false);
        const uid = getPara("uuid", false);
        // console.warn(lastEntry)
        const formData = new FormData();
        formData.append("lid", document.getElementById("lid").innerText);
        formData.append("chat_id", chat_id);
        formData.append("uid", uid);
        formData.append("GET_MESSAGE", "true");

        // console.warn(formData)

        try {
            const resp = await axios.post(CONFIG.APP_API, formData);
            if (resp.data.status !== 401) {
                const newMessages = resp.data.result;
                if (newMessages.length > 0) {
                    // Update chats with new messages
                    setChats(prevChats => [...prevChats, ...newMessages]);

                    // Update last entry ID
                    const newLastEntry = newMessages[newMessages.length - 1].id;
                    setLid(chats.length); // Ensure this is correctly updating
                    document.getElementById("lid").innerText = newLastEntry;
                }
            }
            scrollToBottom();
        } catch (error) {
            console.error('Error syncing messages:', error);
        }


    }



    if (getPara("chat", true) === undefined) {
        window.location.href = "/"
    }
    return (
        <>
            <div id="lid">0</div>
            <div className="my-body" >
                <Header />

                {chats.length < 1 && <center>
                    <h3 className='text-white'>Let's Start Chatting</h3>
                </center>}

                <div className="msg-list" ref={chatContainerRef}>


                    {chats != undefined && chats.length > 0 && <>
                        {chats.map((itm, i) =>
                            <MSGBox date={itm.date + " " + itm.time}
                                key={i}
                                isClient={itm.sender == uid ? true : false}
                                isRead={itm.seen == "Yes" ? true : false}
                                message={itm.description}
                                name={itm.senderName}
                            ></MSGBox>)}
                    </>}

                    <div className="sizedBox"></div>
                    <div ref={messagesEndRef} />
                </div>


                <div className="footer-msg">
                    <div className="row">

                        <textarea
                            value={message}
                            onChange={(e) => { setMessage(e.target.value) }} name="message" id="msg" cols="30" rows="5" className='my-text-area'></textarea>
                        <button onClick={() => { sendMessage() }} >Send</button>
                        <button>😊</button>
                        <button>📃</button>
                        <button>📷</button>
                    </div>
                </div>
            </div>
        </>
    )

    async function sendMessage() {
        const formData = new FormData();
        formData.append("message", message);
        formData.append("uuid", uid);
        formData.append("chat_id", getPara("chat", false));
        formData.append("api_key", getPara("api_key", false));
        formData.append("name", decodeURIComponent(getPara("name", false)));
        formData.append("SEND_MESSAGE", "true");

        setMessage('')
        var resp = await axios.post(CONFIG.APP_API, formData);
        // console.warn(resp.data.status)
    }
}

export default Chat