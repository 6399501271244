
const CONFIG={
    APP_NAME: "DSI Chat", 
    APP_API: "https://talk.devsecit.com/api/v1/"
}


const getPara = (parameter, isLast) => {
    return isLast ? window.location.href.split(`/${parameter}/`)[1] : window.location.href.split(`/${parameter}/`)[1].split('/')[0];
}

const LOGOUT = () => {
    // const cookies = new Cookies();
    // cookies.set('token', '0', { path: '/' });
    // cookies.remove('token', { path: '/' });
    window.location.href = '/';
}

export { getPara, LOGOUT, CONFIG }