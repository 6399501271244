import React, { useEffect, useState } from 'react'
import Header from '../widgets/Header'
import './style.css'
import logo from '../logo.svg';
import axios from 'axios';
import { CONFIG, getPara } from '../functions';

const Auth = () => {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("Please check details")
    const [isSubmitted, setIsSubmitted] = useState(false)

    useEffect(() => {
        const getChatInfo = async () => {
            const formData = new FormData();
            formData.append("GET_CHAT_ID", "true");
            formData.append("domain", getPara("domain", false));
            formData.append("api_key", getPara("api_key", false));

            var resp = await axios.post(CONFIG.APP_API, formData);
            // console.error(resp.data)
            if (resp.data.status === "200") {
                window.location.href = '/chat/' + resp.data.chat_id + "/uuid/" + resp.data.uuid + "/name/" + resp.data.name + "/api_key/" + getPara("api_key", false)
            } else if (resp.data.status === "403") {
                setErrorMessage(resp.data.message);
                setShowError(true);
            }
        }
        getChatInfo();

    }, [])

    return (
        <>
            <div className="my-body" >
                <Header />
                <center> <img src={logo} alt="" className='my-logo' /></center>
                <br />
                <h3 className='text-white'>Let's Start</h3>
                <input type="text" name="name" onChange={(e) => { setName(e.target.value) }} id="name" className='form-control' placeholder='Name' />
                <br />
                <br />

                <input type="number" onChange={(e) => { setPhone(e.target.value) }} name="phone" id="phone" className='form-control' placeholder='Mobile No.' />
                <br />
                {showError && <p className='error-text'>{errorMessage}</p>}
                {isSubmitted ? <input type="button"
                    value="Please wait..."
                    className='primary-btn'
                /> :
                    <input type="submit"
                        value="Continue"
                        className='primary-btn'
                        onClick={() => { loginUser() }}
                    />}
            </div>
        </>
    )


    async function loginUser() {
        if (name.length < 3 || phone.length != 10) {
            setErrorMessage("Please check details")
            setShowError(true)
            setTimeout(() => {
                setShowError(false)
            }, 5000);
        } else {
            setIsSubmitted(true)
            const formData = new FormData();
            formData.append("name", name)
            formData.append("phone", phone)
            formData.append("domain", getPara("domain", false));
            formData.append("api_key", getPara("api_key", false));
            formData.append("LOGIN_REGISTER", "true")

            var resp = await axios.post(CONFIG.APP_API, formData);

            // console.warn(resp.data.result.id);
            if (resp.data.status === "200") {
                window.location.href = "/chat/" + resp.data.chat_id + `/uuid/${resp.data.result.id}/name/${resp.data.result.name}/api_key/${getPara("api_key", false)}`
            } else if (resp.data.status === "403") {
                setErrorMessage(resp.data.message);
                setShowError(true)
            }
        }
    }

}



export default Auth