import React from 'react'

const MSGBox = ({ date, message, isRead, isClient, name }) => {
    return (
        <>
            <div className={isClient != undefined && isClient != false ? "msg-box right" : "msg-box"}>
                <div className="row single-line">
                  { !isClient ? <p style={{ fontWeight:"bold" }}>{decodeURIComponent(name)} </p>  : <p style={{ fontWeight:"bold" }}> Me </p> }
                    <i className='f12'><span>{date}</span></i>
                </div>
                <p>{message}</p>
                {isClient &&
                    <span className='rightTick' style={{color: isRead != undefined && isRead != false ? "green" : ""}} > ✔  </span>}
            </div>
        </>
    )
}

export default MSGBox